import { initialize } from 'launchdarkly-js-client-sdk';
import { readable } from 'svelte/store';
import { merge } from 'lodash-es';
import { Logger } from '@shop-ware/logger';
const logger = new Logger('feature-flags');
let client;
let status;
export async function init(envKey, context, options = {}) {
    if (status && status !== 'error') {
        return;
    }
    status = 'pending';
    client = initialize(envKey, context, merge({
        logger,
        fetchGoals: false
    }, options));
    try {
        await client.waitForInitialization();
        status = 'initialized';
    }
    catch (err) {
        logger.error('launch darkly failed to initialize', err);
        status = 'error';
        throw err;
    }
}
export async function close() {
    verifyClient();
    await client.close();
}
export async function setContext(context) {
    verifyClient();
    return await client.identify(context);
}
export function featureFlagStore(key, defaultValue) {
    verifyClient();
    const value = client.variation(key, defaultValue);
    return readable(value, set => {
        let initHandlerAttached = false;
        const initHandler = () => {
            const currentValue = client.variation(key, defaultValue);
            logger.info(`updating feature flag store for initialized event ${key}`, {
                currentValue
            });
            set(currentValue);
        };
        const changeHandler = (newValue, oldValue) => {
            logger.info(`updating feature flag store ${key}`, { newValue, oldValue });
            set(newValue);
        };
        if (status === 'pending') {
            client.on('initialized', initHandler);
            initHandlerAttached = true;
        }
        client.on(`change:${key}`, changeHandler);
        set(client.variation(key, defaultValue));
        return () => {
            client.off(`change:${key}`, changeHandler);
            if (initHandlerAttached) {
                client.off('initialized', initHandler);
            }
        };
    });
}
function verifyClient() {
    if (!client) {
        throw new Error('Please init the feature flag environment');
    }
}
